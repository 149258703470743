:root {
    --color-primary: #32642d;
    --color-primary-hover: #68ADF8;
    --color-primary-transparent: hsla(212, 91%, 31%, 0.2);

    --color-secondary: #fbbc05;
    --color-secondary-hover: #fbbc05;
    --color-secondary-transparent: hsla(212, 91%, 31%, 0.2);
    --color-red: #B0182E;
}

.tab-pane {
    transition: transform 0.3s ease-in-out;
}

body {
    height: 100vh;
    width: 100vw;
    background-color: #f8f9fa;
    /* Light grey background */
}

.btn-default {
    background-color: #32642d;
    color: #fbbc05;
}

.btn-default:hover {
    background-color: #fbbc05;
    color: #32642d;
}

.nav-link {
    color: var(--color-primary) !important;
}

.nav-link.active {
    background-color: var(--color-primary) !important;
    color: var(--color-secondary) !important;
}

.form-control:focus {
    border-color: var(--color-primary) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--color-primary) !important;
}

.form-control:hover {
    border-color: var(--color-primary) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--color-primary) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: var(--color-red) !important;
}

.form-check-input:checked {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #2d2d2d !important;
}

.form-check-input:focus {
    border-color: var(--color-primary) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--color-primary) !important;
}

.container {
    max-width: 500px;
    height: 100%;
}

.card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    background-color: #32642d;
    /* Blue-grey color */
    color: #fbbc05;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-header .nav-link {
    color: black;
}

.card-header .nav-link.active {
    background-color: #7498ba;
    /* Darker blue-grey */
    border-radius: 5px;
}

.form-label {
    font-weight: bold;
}

.form-control {
    border-radius: 5px;
}

.btn-primary {
    background-color: #5a6e7f;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.btn-primary:hover {
    background-color: #3c4f61;
}

.invalid-feedback {
    font-size: 0.875em;
    color: red;
}

textarea.form-control {
    height: 100px;
}

@media (max-width: 576px) {
    .container {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .signup-form {
        height: 62vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

/* NAVBAR BOTTOM */

.menu-container {
    background-color: var(--color-primary);
    padding: 10px;
    border-top: 2px solid #002745;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menu-item {
    text-decoration: none;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    transition: all 0.3s ease;
}

.menu-item:hover {
    color: var(--color-secondary);
}

.menu-item.active-menu {
    color: var(--color-secondary);
    border-radius: 10px;
}

.menu-item i {
    font-size: 24px;
    margin-bottom: 5px;
}

.menu-label {
    font-size: 14px;
}

/* TOAST */

#toast {
    position: fixed;
    bottom: 100px;
    width: 300px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-primary);
    color: var(--color-secondary);
    padding: 15px;
    border-radius: 5px;
    opacity: 0;
    z-index: 9999;
    transition: opacity 0.3s ease-in-out;
    /* Transisi opacity selama 0.3 detik */
}

#toast.toast-active {
    opacity: 0.9;
}